import { skipToken } from '@reduxjs/toolkit/dist/query';
import { config } from 'config';
import { useFetchShiftsRulesQuery } from 'features/api/shifts';

// минимальный интервал на сетке в минутах
const MIN_TIME_INTERVAL = 5;
// максимальный интервал на сетке в минутах
const MAX_TIME_INTERVAL = 30;

const DEFAULT_TIME_INTERVAL = 15;

export const useTimeInterval = ({
  placeId,
  restaurantId,
  date,
}: {
  placeId: number;
  restaurantId: number;
  date: string;
}) => {
  const isTimeShiftForGridEnabled = config.enableShiftIntervalForGrid;
  // загружаем смены, чтобы определить настроенный минимальный интервал брони для каждой смены на дату
  const { data: rules } = useFetchShiftsRulesQuery(
    isTimeShiftForGridEnabled
      ? {
          place_id: placeId,
          restaurant_id: restaurantId,
          date,
        }
      : skipToken
  );
  // интервал на сетке равен самому минимальному интервалу из смен на дату, но не меньше, чем MIN_TIME_INTERVAL
  const timeInterval = isTimeShiftForGridEnabled
    ? Math.max(
        rules?.reduce(
          (acc, rule) => Math.min(acc, rule.time_interval),
          MAX_TIME_INTERVAL
        ) || 0,
        MIN_TIME_INTERVAL
      )
    : DEFAULT_TIME_INTERVAL;

  return timeInterval;
};
